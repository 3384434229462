import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"

const ComboFlynow = () => {
  const data = {
    id: "combo_app_cursos",
    title: "PREMIUM VITALÍCIO + 2 CURSOS",
    discontPercentage: 30,
    URL_CHECKOUT: "https://buy.stripe.com/7sI5m73e1g4B4p27sP?locale=pt-BR&prefilled_promo_code=COMBO30",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [Products.apps[0]]
      },
      {
        section: "CURSOS",
        data: Products.cursos
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow
